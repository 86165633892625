import * as React from "react";
import {
  Avatar,
  Box,
  Flex,
  Heading,
  Text
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const Testimonial: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      minW={{
        base: '290px',
        sm: '430px'
      }}
      w={{
        base: '290px',
        sm: '430px'
      }}
      background="white"
      borderWidth="1px"
      borderColor="border.gainsboro"
      borderRadius="16px"
      margin="0 20px"
    >
      <Box
        padding={{
          base: '20px',
          sm: '40px'
        }}
      >
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          gap="16px"
          pb="30px"
        >
          <Avatar
            name={ props.node.frontmatter.author_name }
          />
          <Box>
            <Heading
              as="h3"
              color="text.primary"
              fontSize="16px"
              fontWeight={ 600 }
            >
              { props.node.frontmatter.author_name }
            </Heading>
            <Heading
              as="h4"
              color="text.secondary"
              fontSize="16px"
              fontWeight={ 400 }
            >
              { props.node.frontmatter.author_title }
            </Heading>
          </Box>
        </Flex>
        <Text
          color="text.secondary"
        >
          { props.node.frontmatter.testimonial }
        </Text>
      </Box>
    </Box>
  )
}
