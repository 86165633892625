import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
  Heading,
  LinkBox,
  LinkOverlay,
  Text,
  VStack
} from "@chakra-ui/react";

import { IconFactory } from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Service: React.FC<Props> = ( props ) => {
  return (
    <LinkBox
      position="relative"
      minW={{
        base: '290px',
        sm: '389px'
      }}
      w={{
        base: '290px',
        sm: '389px'
      }}
      background="white"
      borderWidth="1px"
      borderColor="border.gainsboro"
      margin="0 20px"
      transition="all 0.2s ease-in-out"
      _hover={{
        background: 'background.aliceblue'
      }}
    >
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing="28px"
        padding={{
          base: '20px',
          sm: '40px'
        }}
      >
        <IconFactory
          icon={ props.node.frontmatter.icon }
          w="auto"
          h="32px"
          color="primary.900"
        />
        <Heading
          as="h3"
          color="text.primary"
          fontSize="24px"
          fontWeight={ 600 }
        >
          <LinkOverlay
            as={ GatsbyLink }
            to={ props.node.fields.slug }
          >
            { props.node.frontmatter.heading }
          </LinkOverlay>
        </Heading>
        <Text
          color="text.secondary"
          fontSize="16px"
          fontWeight={ 400 }
        >
          { props.node.frontmatter.short_description }
        </Text>
      </VStack>
    </LinkBox>
  )
}
