import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import useEmblaCarousel from "embla-carousel-react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Text,
  VStack
} from "@chakra-ui/react";

import { EmblaOptionsType } from "embla-carousel-react";

import {
  ArrowLeftIcon,
  ArrowRightIcon
} from "../ScalableVectorGraphics";
import { SectionContainer } from "../Section";

import {
  Project,
  Service,
  Testimonial
} from "./Cards";


interface Props {
  nodes: any[]
  options: EmblaOptionsType
  template: string

  heading?: React.ReactNode | string | undefined
  headingAlignment?: "center" | "space-between" | undefined
  headingWidth?: object | string | undefined
  description?: string | undefined
  slug?: string | undefined
  buttonText?: string | undefined

  emblaPadding?: object | string
}


export const Carousel: React.FC<Props> = ( props ) => {
  const [viewportRef, embla] = useEmblaCarousel( props.options );
  const [prevButtonEnabled, setPrevButtonEnabled] = useState( false );
  const [nextButtonEnabled, setNextButtonEnabled] = useState( false );

  const scrollPrev = useCallback( () => embla && embla.scrollPrev(), [embla] );
  const scrollNext = useCallback( () => embla && embla.scrollNext(), [embla] );

  const onSelect = useCallback( () => {
    if ( !embla ) return;

    setPrevButtonEnabled( embla.canScrollPrev() );
    setNextButtonEnabled( embla.canScrollNext() );
  }, [embla] );

  useEffect(() => {
    if ( !embla ) return;

    onSelect();

    embla.on( "select", onSelect );
  }, [embla, onSelect] );

  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.template ) {
      case 'project':
        return (
          <Project
            key={ _index }
            node={ node.node }
          />
        );
      case 'service':
        return (
          <Service
            key={ _index }
            node={ node.node }
          />
        );
      case 'testimonial':
        return (
          <Testimonial
            key={ _index }
            node={ node.node }
          />
        );
      default:
        return null;
    }
  });

  return (
    <VStack
      justify={ props.headingAlignment }
      align={ props.headingAlignment === "center" ? 'center' : 'flex-start' }
      spacing="40px"
    >
      { props.heading &&
        <SectionContainer>
          <Flex
            direction={ props.headingAlignment === "center" ? 'column' : 'row' }
            justifyContent={ props.headingAlignment }
            alignItems={ props.headingAlignment === "center" ? 'center' : 'flex-end' }
            gap={ props.headingAlignment === "center" ? '20px' : 0 }
          >
            <VStack
              justify="flex-start"
              align="flex-start"
              spacing="20px"
              w={ props.headingWidth }
            >
              <Heading
                as="h2"
                fontSize="48px"
                fontWeight={ 700 }
                textAlign={ props.headingAlignment === "center" ? 'center' : 'left' }
              >
                { props.heading }
              </Heading>
              { props.description &&
                <Text>
                  { props.description }
                </Text>
              }
              { props.slug &&
                <Box>
                  <Button
                    as={ GatsbyLink }
                    to={ props.slug }
                    variant="solid"
                    h="42px"
                    background="primary.900"
                    borderRadius="21px"
                    borderColor="primary.900"
                    color="white"
                    fontSize="14px"
                    fontWeight={ 500 }
                  >
                    { props.buttonText }
                  </Button>
                </Box>
              }
            </VStack>
            <HStack
              justify={ props.headingAlignment === "center" ? 'center' : 'flex-end' }
              align="center"
              spacing="8px"
            >
              <IconButton
                aria-label="Previous slide"
                icon={ <ArrowLeftIcon color="primary.900" /> }
                disabled={ !prevButtonEnabled }
                onClick={ scrollPrev }
                background="transparent"
                color="primary.900"
                borderWidth="1px"
                borderColor="primary.900"
                borderRadius="50%"
                _hover={{
                  opacity: '0.6'
                }}
              />
              <IconButton
                aria-label="Next slide"
                icon={ <ArrowRightIcon color="primary.900" /> }
                disabled={ !nextButtonEnabled }
                onClick={ scrollNext }
                background="transparent"
                color="primary.900"
                borderWidth="1px"
                borderColor="primary.900"
                borderRadius="50%"
                _hover={{
                  opacity: '0.6'
                }}
              />
            </HStack>
          </Flex>
        </SectionContainer>
      }
      <Box className="Embla"
        position="relative"
        w="100%"
      >
        <Box className="EmblaViewport"
          ref={ viewportRef }
          overflow="hidden"
          w="100%"
          _hover={{
            cursor: 'grab'
          }}
          _active={{
            cursor: 'grabbing'
          }}
        >
          <Flex className="EmblaContainer"
            padding={ props.emblaPadding }
            userSelect="none"
            css={{
              WebkitTouchCallout: 'none',
              KhtmlUserSelect: 'none',
              WebkitTapHighlightColor: 'transparent'
            }}
          >
            { nodeList }
          </Flex>
        </Box>
      </Box>
    </VStack>
  )
}
