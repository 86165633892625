import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const Project: React.FC<Props> = ( props ) => {
  return (
    <Box
      position="relative"
      minW={{
        base: '290px',
        sm: '400px'
      }}
      w={{
        base: '290px',
        sm: '400px'
      }}
      margin="0 20px"
    >
      <VStack
        justify="flex-start"
        align="flex-start"
        spacing="16px"
      >
        <Image
          as={ GatsbyImage }
          image={ props.node.frontmatter.image.childImageSharp.gatsbyImageData }
        />
        <Heading
          as="h3"
          color="text.primary"
          fontSize="20px"
          fontWeight={ 700 }
        >
          { props.node.frontmatter.heading }
        </Heading>
        <Heading
          as="h4"
          color="text.primary"
          fontSize="18px"
          fontWeight={ 400 }
        >
          <Text
            as="span"
            color="primary.900"
            fontWeight={ 700 }
          >
            ~
          </Text>
          &nbsp;
          { props.node.frontmatter.project_type }
        </Heading>
      </VStack>
    </Box>
  )
}
